import React, {useState, useEffect} from 'react'
import {  Button, Grid, makeStyles, withStyles, Typography, Box} from '@material-ui/core';
import { ValidatorForm } from 'react-material-ui-form-validator';
import StripeCheckout from 'react-stripe-checkout';
import Axios from 'axios';
import ServiceFields from './ServiceFields';
import ContactFields from './ContactFields';
import green from "@material-ui/core/colors/green";
const useStyles = makeStyles((theme) => ({
 button:{
   margin:theme.spacing(0, 0, 2, 0 ),   
 },
 containedGreen: {
  color: '#fff',
  backgroundColor: green[700],
  "&:hover": {
    backgroundColor: green[700],
    // Reset on touch devices, it doesn't add specificity
    "@media (hover: none)": {
      backgroundColor: green[500]
    }
  }
},
// cost:{
//     color: "#008000",
//     fontWeight:500,
//     position:'fixed', 
//     right:0, 
//     top:'50%', 
//     transform:'translateY(-50%)', 
//     background:"#fff", 
//     border:'1px solid green', 
//     borderRadius:'10px', 
//     padding:'15px',
//     '&span.desktop':{
//       [theme.breakpoints.down('xs')]: {        
//         display:'none',
//       },
//     },
//     '&span.mobile':{
//       [theme.breakpoints.up('xs')]: {        
//         display:'none',
//       },
//     } 
// }

}));
const GreenTextTypography = withStyles({
  root: {
    color: "#008000",
    fontWeight:500,
    
  },   
})(Typography);
const TotalCost = withStyles({
  root: {
    color: "#008000",
    fontWeight:500,
    position:'fixed', 
    right:0, 
    top:'50%', 
    transform:'translateY(-50%)', 
    background:"#fff", 
    border:'1px solid green', 
    borderRadius:'10px', 
    padding:'15px'           
  },   
})(Typography);

const Form = (props)=>{
    const classes = useStyles();
    const {servicePlaceAddress, servicePlaceAddressPostCode, servicePlaceAddressText, mapBoxAddress, setMapBoxAddress} = props;
    const serviceAddress = servicePlaceAddress.split(",");
    const formattedAddress = {
      city:serviceAddress[1] ? serviceAddress[1].trimStart() : "", 
      country:serviceAddress[3] ? serviceAddress[3].trimStart() : "", 
      line1:servicePlaceAddressText,  
      postal_code: servicePlaceAddressPostCode, 
      state:serviceAddress[2] ? serviceAddress[2].trimStart() : "" 
    };    
    const [service, setService] = useState('grass-cutting');
    const [serviceType, setServiceType] = useState('weekly');
    const [servicePlan, setServicePlan] = useState('basic');
    const [propertyArea, setPropertyArea] = useState(null);
    const [snowServiceType, setSnowServiceType] = useState('automated');
    const [snowServicePlan, setSnowServicePlan] = useState('front-door-walkway');
    const [driveWaySize, setDriveWaySize] = useState(null);
    const [serviceFinalPrice, setServiceFinalPrice] = useState(null); 
    const [email, setEmail] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');    
    const [apiPropertySize, setAPIPropertySize] = useState(false);
    const [apiCarParkingSize, setAPICarParkingSize] = useState(false);
    const [mailStatus, setMailStatus] = useState(false);
    const [propertyAreaAPIDataEmpty, setPropertyAreaAPIDataEmpty] = useState('not_empty'); 
    const [driveWaySizeAPIDataEmpty, setDriveWaySizeAPIDataEmpty] = useState('not_empty');     
    const onToken = (token) => {     
      if(token) {
        Axios.post(
          `${process.env.REACT_APP_SERVER_ORIGIN_PRODUCTION}/api/payment`,
            {
              email: email,
              id: token.id,
              amount:serviceFinalPrice,
              name:firstName+' '+lastName,
              address:formattedAddress,
              service:service.replace(/(^\w|\s\w)/g, m => m.toUpperCase()).split('-').join(' ')
            }
        ).then(function(res) {
          if(res.data.message === 'charge_success'){
            Axios.post(
              `${process.env.REACT_APP_SERVER_ORIGIN_PRODUCTION}/api/order-info-email`,
                {
                  email: email,
                  name:firstName+' '+lastName,
                  phone:phoneNumber,                    
                  amount:serviceFinalPrice,                    
                  address:servicePlaceAddress,
                  service:service,
                  serviceType:service === 'grass-cutting' ? serviceType : snowServiceType,
                  servicePlan:service === 'grass-cutting' ? servicePlan : snowServicePlan,
                  propertyArea:propertyArea,                    
                  driveWaySize:driveWaySize
                }
              ).then(function(email_res){
                setMailStatus(true);
              })
          }
          if (res.data.status === "error") {
            console.log(res.data.msg);
          } 
        })
      }
    }

    const handleSubmit = (event) => { 
      event.preventDefault();      
      if(servicePlaceAddress !== ""){        
        setMapBoxAddress(true); 
      }else{        
        setMapBoxAddress(false);
      }    
    };    
    useEffect(() => {
      let finalPrice = null;
      if(service === 'grass-cutting'){
        let pricePerSF = 0.03;        
        if(serviceType === 'weekly'){ // weekly
          pricePerSF = 0.02;
          if(servicePlan === 'basic'){
            finalPrice = propertyArea * pricePerSF;
          }else{ // Premium
            finalPrice = (propertyArea * pricePerSF) + 10;
          }
        }else if(serviceType === 'bi-weekly'){ // bi-weekly
          pricePerSF = 0.025;
          if(servicePlan === 'basic'){
            finalPrice = propertyArea * pricePerSF;
          }else{ // Premium
            finalPrice = (propertyArea * pricePerSF) + 10;
          }

        }else{ //one time
          if(servicePlan === 'basic'){
            finalPrice = propertyArea * pricePerSF;
          }else{ // Premium
            finalPrice = (propertyArea * pricePerSF) + 10;
          }
        }
        setServiceFinalPrice(finalPrice);
      }else{
        let pricePerCar = 10;        
        if(snowServiceType === 'automated'){ // weekly
          pricePerCar = 6;
          if(snowServicePlan === 'front-door-walkway'){
            finalPrice = driveWaySize * pricePerCar;
          }else{ 
            finalPrice = (driveWaySize * pricePerCar) + 15;
          }        
        }else{ 
          if(snowServicePlan === 'front-door-walkway'){
            finalPrice = driveWaySize * pricePerCar;
          }else{ 
            finalPrice = (driveWaySize * pricePerCar) + 15;
          }
        }
        setServiceFinalPrice(finalPrice);
      }
    }, [service, serviceType, servicePlan, propertyArea, snowServiceType, snowServicePlan, driveWaySize, setServiceFinalPrice]);
    useEffect(()=>{      
      getPropertyData(servicePlaceAddress).then(response =>{                    
        let finalData = response;         
        if(finalData.areaSF !== 0){
          setPropertyArea(finalData.areaSF);
          setAPIPropertySize(true);
        }else{
          setPropertyArea("");
          setAPIPropertySize(false);
          setPropertyAreaAPIDataEmpty('empty');
        }      
        if(finalData.parkingarea !== -1 ){
          setDriveWaySize(finalData.parkingarea);
          setAPICarParkingSize(true);
        }else{
          setDriveWaySize("");
          setAPICarParkingSize(false);
          setDriveWaySizeAPIDataEmpty('empty');
        }
      }).catch(error=>{
        console.log(error)
      });
    }, [servicePlaceAddress,setPropertyArea, setDriveWaySize, setAPIPropertySize, setAPICarParkingSize, setPropertyAreaAPIDataEmpty, setDriveWaySizeAPIDataEmpty]);

    const getPropertyData = (placeAddress) => {
      return new Promise((resolve,reject)=>{
        if(placeAddress){
         fetch(`https://property.melissadata.net/v4/WEB/LookupProperty/?id=${process.env.REACT_APP_MELISSA_API_ACCESS_TOKEN}&cols=GrpPropertySize&format=json&ff=${placeAddress}`,{
          //fetch(`https://property.melissadata.net/v4/WEB/LookupProperty/?id=bRLTSBfD9g_h-zhYWlPqOl**nSAcwXpxhQ0PC2lXxuDAZ-**&cols=GrpPropertySize&format=json&ff=${placeAddress}`,{
           method: 'GET'
          }).then(response =>{
            response.json().then(data =>{               
              let propertyData = {areaSF:0,parkingarea:-1};
              if(data.Records !== null && data.Records[0].hasOwnProperty('PropertySize')){                
                let areaLotSF = Math.round(data?.Records[0]?.PropertySize?.AreaLotSF);
                let carParking = Math.round(data?.Records[0]?.PropertySize?.ParkingCarport);    
                propertyData={areaSF:areaLotSF,parkingarea:carParking!==0 ?carParking:-1};
              } 
              return resolve(propertyData); 
            });
          }).catch(error =>{
            reject(error);
          });
        }else{
          reject('error');
        }
      });
    };

    useEffect(()=>{
      if(mailStatus){
        setTimeout(()=>{
          setMailStatus(false);
        },5000);
      }
    },[mailStatus, setMailStatus])    
    
    return (      
      <ValidatorForm  onSubmit={handleSubmit} instantValidate={true}>
        <ServiceFields
          {...props}
          service={service}
          setService={setService}
          serviceType={serviceType}
          setServiceType={setServiceType}
          servicePlan={servicePlan}
          setServicePlan={setServicePlan}
          propertyArea={propertyArea}
          setPropertyArea={setPropertyArea}
          snowServiceType={snowServiceType}
          setSnowServiceType={setSnowServiceType}
          snowServicePlan={snowServicePlan}
          setSnowServicePlan={setSnowServicePlan}
          driveWaySize={driveWaySize}
          setDriveWaySize={setDriveWaySize}
          apiPropertySize={apiPropertySize}
          setAPIPropertySize={setAPIPropertySize}
          apiCarParkingSize={apiCarParkingSize}
          setAPICarParkingSize={setAPICarParkingSize}
          servicePlaceAddress={servicePlaceAddress}
          propertyAreaAPIDataEmpty={propertyAreaAPIDataEmpty}
          setPropertyAreaAPIDataEmpty={setPropertyAreaAPIDataEmpty}
          driveWaySizeAPIDataEmpty={driveWaySizeAPIDataEmpty}
          setDriveWaySizeAPIDataEmpty={setDriveWaySizeAPIDataEmpty}
        />
         {mailStatus &&              
          <GreenTextTypography variant="subtitle1" align="center">
            You have successfully placed an order for service. Please check your email for more details.
          </GreenTextTypography> 
          }
          {
          serviceFinalPrice !== null && serviceFinalPrice !== 0 && 
          <TotalCost 
          variant="subtitle1"
          className={classes.cost}
          >
          <Box display={{ xs: 'none', md: 'block' }}>Estimated Cost for Service: ${Math.round(serviceFinalPrice * 100) / 100}</Box>
          <Box display={{ xs: 'block', md: 'none' }}>Cost: ${Math.round(serviceFinalPrice * 100) / 100}</Box>            
        </TotalCost>
        } 
        <ContactFields 
          {...props}
          firstName={firstName}
          setFirstName={setFirstName}
          lastName={lastName}
          setLastName={setLastName}
          email={email}
          setEmail={setEmail}
          phoneNumber={phoneNumber}
          setPhoneNumber={setPhoneNumber}
        />        
        <Grid container justify='center' className={classes.button}>  
        <StripeCheckout
              name="BC Stripe Test Payment" 
              image="/bc-logo.png"
              ComponentClass="div"
              description={`${service.replace(/(^\w|\s\w)/g, m => m.toUpperCase()).split('-').join(' ')} service cost is $${Math.round(serviceFinalPrice * 100) / 100}`} // the pop-in header subtitle
              ComponentClass="div"
              amount={(Math.round(serviceFinalPrice * 100) / 100) * 100} // cents
              currency="USD"
              stripeKey={process.env.REACT_APP_STRIPE_TEST_API_KEY}
              locale="en"
              email={email}        
              reconfigureOnUpdate={false}
              token={onToken}                
              >         
              <Button type="submit" variant="contained" className={classes.containedGreen}
                  onClick={(e) => {
                    if(servicePlaceAddress === '' || 
                    (propertyArea === '' && driveWaySize === '') ||                    
                    email === '' || 
                    firstName === '' || 
                    lastName === '' || 
                    phoneNumber === ''){
                      e.stopPropagation();                       
                    }
                  }}
                >
                  Book Service
                </Button>
            </StripeCheckout> 
        </Grid>
      </ValidatorForm>      
    )
}
export default Form;