import React, {useState} from 'react'
import {
  Container, 
  Box, 
  Typography,  
  withStyles
} from '@material-ui/core';
import {ThemeProvider } from '@material-ui/core/styles';
import theme from './components/Theme';
import Geocoder from 'react-mapbox-gl-geocoder';
import './App.css';
import Form from './components/form';
import { StripeProvider, Elements } from 'react-stripe-elements';

const RedTextTypography = withStyles({
  root: {
    color: "#f44336"    
  }
})(Typography);
export default function App(props){
  const mapAccess = {
    mapboxApiAccessToken: process.env.REACT_APP_MAPBOX_API_ACCESS_TOKEN
  }   
  const queryParams = {
      country: 'us'
  }
  const [viewPort, setViewPort] = useState({});
  const [servicePlaceAddress, setServicePlaceAddress] = useState('');
  const [servicePlaceAddressPostCode, setServicePlaceAddressPostCode] = useState('');
  const [servicePlaceAddressText, setServicePlaceAddressText] = useState(''); 
  const [mapBoxAddress, setMapBoxAddress] = useState(true); 

  const onSelected = (viewport, item)=>{    
    setViewPort(viewPort);
    setServicePlaceAddress(item.place_name); 
    setServicePlaceAddressPostCode(item.address); 
    setServicePlaceAddressText(item.text);
    if(item.place_name){
      setMapBoxAddress(true);
    }   
  } 
    
  return (  
    <ThemeProvider theme={theme}>       
  <Container maxWidth="sm">
    <Box my={4}>
      <Typography variant="h4" component="h1" align="center" gutterBottom={true}>
        Landscape Service Quote
      </Typography>
      {!mapBoxAddress &&
      <RedTextTypography 
            variant="body2" 
            component="h2" 
            gutterBottom>
             Address is required, please search for it.               
            </RedTextTypography>
            }              
      <Typography variant="body2" component="h2" color="textPrimary" gutterBottom>
        Search for Address
      </Typography>
      <Geocoder
         {...mapAccess} onSelected={onSelected} viewport={viewPort} hideOnSelect={true}
          queryParams={queryParams} updateInputOnSelect={true} initialInputValue=""
      />       
    </Box>
    {/* {servicePlaceAddress !== "" && viewPort !== "" &&     */}
    <StripeProvider apiKey={process.env.REACT_APP_STRIPE_TEST_API_KEY}>
      <Elements>
        <Form 
          {...props} 
          servicePlaceAddress={servicePlaceAddress} 
          servicePlaceAddressPostCode={servicePlaceAddressPostCode} 
          servicePlaceAddressText={servicePlaceAddressText}
          mapBoxAddress={mapBoxAddress}
          setMapBoxAddress={setMapBoxAddress} 
        />
      </Elements>
    </StripeProvider>
     {/* } */}
  </Container> 
  </ThemeProvider>          
    )   
}