import React from 'react';
import {  
    Box, 
    Typography,        
    FormControl,     
    makeStyles,  
    Card,
    CardContent,  
    CardActionArea,
    Grid,
    withStyles
  } from '@material-ui/core';
import globalData from '../data/global';
import { SelectValidator} from 'react-material-ui-form-validator';
const useStyles = makeStyles((theme) => ({
    formControl: {
      margin: theme.spacing(3, 0, 0, 0),      
      width:'100%',      
    },      
    customCard: {
      maxWidth: 200,
      background: 'white',
      borderRadius: 10,
      border: '2px solid #ccc',
      color: 'black',
      "&.active":{
        border:'2px solid green'
      }
    },
    serviceCustomCard:{
      maxWidth: 200,
      minHeight:'160px',
      display:'flex',
      background: 'white',
      borderRadius: 10,
      border: '2px solid #ccc',
      color: 'black',
      "&.active":{
        border:'2px solid green'
      },
      [theme.breakpoints.down('xs')]: {
        maxWidth: 'inherit',
        width:'100%',

      },
    },    
    grid:{
      marginBottom:'10px',
      [theme.breakpoints.down('xs')]: {        
        flexWrap:'wrap',
      },
    },
    gridCard:{
      margin:'10px 10px 10px 0',
      [theme.breakpoints.down('xs')]: {        
        width:'100%',
      },
    },   
  }));
  const RedTextTypography = withStyles({
    root: {
      color: "#f44336",      
    }
  })(Typography);
const ServiceFields = (props)=>{
    const {
        service,
        setService,
        serviceType,
        setServiceType,
        servicePlan,
        setServicePlan,
        propertyArea,
        setPropertyArea,
        snowServiceType,
        setSnowServiceType,
        snowServicePlan,
        setSnowServicePlan,
        driveWaySize,
        setDriveWaySize,
        apiPropertySize,
        setAPIPropertySize,
        apiCarParkingSize,
        setAPICarParkingSize,
        servicePlaceAddress,
        propertyAreaAPIDataEmpty,
        setPropertyAreaAPIDataEmpty,
        driveWaySizeAPIDataEmpty,
        setDriveWaySizeAPIDataEmpty
      } = props;
    const propertyInfo = () => {
        return [
          { label: 'Extra Small (under 2,500 sqft)', value: 2500 },
          { label: 'Small (2,500 to 5,000 sqft)', value: 5000 },
          { label: 'Medium (5,000 to 7,500 sqft)', value: 7500 },
          { label: 'Large (7,500 to 10,000 sqft)', value: 10000 },
        ].map(propertySize => (
          <option key={propertySize.value} value={propertySize.value}>
            {propertySize.label}
          </option>
        ));
      };
      const driveWaySizeInfo = () => {
        return [        
            { label: '2 or less', value: 2 },
            { label: '4 or less', value: 4 },
            { label: '6 or less', value: 5 },
            { label: '8 or less', value: 8 },
            { label: '10 or less', value: 10 },
        ].map(driveWaySize => (
          <option key={driveWaySize.value} value={driveWaySize.value}>
            {driveWaySize.label}
          </option>
        ));
      };
    const classes = useStyles(); 
    const handleServiceCardClick = (service) => setService(service);
    const handleServiceTypeChange = (serviceType) => (service === 'snow-removal' ? setSnowServiceType(serviceType) : setServiceType(serviceType));     
    const handleServicePlanChange = (servicePlan) => (service === 'snow-removal' ? setSnowServicePlan(servicePlan) : setServicePlan(servicePlan));
    const handlePropertyChange = (event) => {
      setPropertyArea(event.target.value);
      setAPIPropertySize(false);
      setPropertyAreaAPIDataEmpty('not_empty');
    }
    const handleDriveWaySizeChange = (event) => {
      setDriveWaySize(event.target.value);
      setAPICarParkingSize(false);
      setDriveWaySizeAPIDataEmpty('not_empty')
    }
    return (
        <div style={{color:"#333", fontSize:"1rem"}}>
        <Box my={4}>
          <Typography variant="h5" component="h2" color="textPrimary">
            Type of Work
          </Typography>          
          <FormControl component="fieldset" className={classes.formControl}>
          <Typography variant="body2" component="h2" color="textPrimary">
              Select Service
          </Typography>
            <Grid container className={classes.grid}>
              {globalData.servicesData && globalData.servicesData.map((serviceInfo, serviceInfoIndex)=>
                <Grid item md={4} key={serviceInfoIndex} className={classes.gridCard}>
                <Card className={`${classes.customCard} ${service===serviceInfo.slug ? 'active':''}`}>
                  <CardActionArea disableRipple onClick={()=>handleServiceCardClick(serviceInfo.slug)}>
                    <CardContent>
                        <Typography variant="body2" color="textPrimary" align="center" component="p">
                          {serviceInfo.title}
                        </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Grid>
              )}
            </Grid>                       
          </FormControl> 
          <FormControl component="fieldset" className={classes.formControl}>
            {apiPropertySize && (propertyArea !== null || propertyArea !== "") && service === 'grass-cutting' && <div>Your property size is <b>{propertyArea}</b> sft</div>}
            {apiCarParkingSize && (driveWaySize !== null || driveWaySize !== "") && service === 'snow-removal' && <div>Your car parking size is <b>{driveWaySize}</b></div>}
            {!apiPropertySize && propertyAreaAPIDataEmpty === 'empty' && servicePlaceAddress !== '' && (propertyArea === null || propertyArea === "") && service === 'grass-cutting' &&            
            <RedTextTypography 
            variant="body2" 
            component="h2" 
            gutterBottom>
              Apoligies, We are unable to find the area of your property, Please verify your address once again or choose from the below dropdown              
            </RedTextTypography>
            }
            {!apiCarParkingSize && driveWaySizeAPIDataEmpty === 'empty' && servicePlaceAddress !== '' && (driveWaySize === null || driveWaySize === "") && service === 'snow-removal' &&            
            <RedTextTypography 
            variant="body2" 
            component="h2" 
            gutterBottom>
             Apoligies, We are unable to find the number of cars in your property, Please verify your address once again or choose from the below dropdown            
            </RedTextTypography>
            }  
            {!apiPropertySize && service === 'grass-cutting' && 
            <div style={{width:'100%', display:'inline-grid'}}>
            <Typography 
            variant="body2" 
            component="h2" 
            color="textPrimary" 
            gutterBottom>
              Enter Property Details
            </Typography> 
              <SelectValidator
                name="propertyArea"
                id="propertyArea"
                onChange={handlePropertyChange}
                value={propertyArea}
                variant="outlined"
                SelectProps={{
                  native: true
                }}
                validators={["required"]}
                errorMessages={["Property size selection is required"]}                
              >
                <option value=""></option>
                {propertyInfo()}
              </SelectValidator>
              </div>
              }
              {!apiCarParkingSize && service === 'snow-removal' && 
              <div style={{width:'100%', display:'inline-grid'}}>
              <Typography 
              variant="body2" 
              component="h2" 
              color="textPrimary" 
              gutterBottom>
                Up to how many cars fit in your driveway?
                </Typography>
              <SelectValidator
                name="driveWaySize"
                id="driveWaySize"
                onChange={handleDriveWaySizeChange}
                value={driveWaySize}
                variant="outlined"
                SelectProps={{
                  native: true
                }}                
                validators={["required"]}
                errorMessages={["Driveway size selection is required"]}
              >
                <option value=""></option>
                {driveWaySizeInfo()}
              </SelectValidator>
              </div>
              }                                     
            </FormControl>       
        </Box>
        <Box my={4}>
          <Typography variant="h5" component="h2" color="textPrimary">
            Pick the package that works for you            
          </Typography>
          <FormControl component="fieldset" className={classes.formControl}>           
          <Typography variant="body2" component="h2" color="textPrimary">
            {service === 'grass-cutting' ? 
            'How often do you want us to mow your grass? Please note if you order a recurring weekly or bi-weekly service it includes a one-time initial cleanup that is quoted on-site. Price is per service'
            :'How often do you want us to clear your snow? Please note automated snow removal is sent when there is 2 inches or 5 centimetres of snow accumulation. Price is per service.'
            }
            </Typography>
          <Grid container className={classes.grid} alignItems="center" wrap="nowrap">
            {globalData[service.split('-').join("")][0].serviceTypeData && globalData[service.split('-').join("")][0].serviceTypeData.map((serviceTypeInfo, serviceTypeIndex)=>                
                <Grid item md={4} key={serviceTypeIndex} className={classes.gridCard}>
                  <Card className={`${classes.serviceCustomCard} ${service === 'snow-removal' ? snowServiceType===serviceTypeInfo.slug ? 'active':'' : serviceType===serviceTypeInfo.slug ? 'active':''}`}>
                    <CardActionArea disableRipple onClick={()=>handleServiceTypeChange(serviceTypeInfo.slug )}>
                      <CardContent>
                          <Typography variant="body2" color="textPrimary" align="center" component="p">
                            {serviceTypeInfo.title}
                            <Typography color="textSecondary" component="span" display="block">
                            {serviceTypeInfo.description}
                            </Typography>
                          </Typography>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                </Grid>
              )}
          </Grid>
          <Typography variant="body2" component="h2" color="textPrimary">
            {service === 'grass-cutting' ? 'Pick the package that works for you' : 'Do you need extra options?'}
            </Typography>
          <Grid container className={classes.grid}> 
          {globalData[service.split('-').join("")][1].servicePlanData && globalData[service.split('-').join("")][1].servicePlanData.map((servicePlanInfo, servicePlanIndex)=>         
            <Grid item md={4} key={servicePlanIndex} className={classes.gridCard}> 
              <Card className={`${classes.serviceCustomCard} ${service === 'snow-removal' ? snowServicePlan===servicePlanInfo.slug ? 'active':'' : servicePlan===servicePlanInfo.slug ? 'active':''}`}>
                  <CardActionArea disableRipple onClick={()=>handleServicePlanChange(servicePlanInfo.slug)}>
                    <CardContent>
                        <Typography variant="body2" color="textPrimary" align="center" component="p">
                          {servicePlanInfo.title}
                          <Typography color="textSecondary" component="span" display="block">
                            {servicePlanInfo.description}
                            </Typography>
                        </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
            </Grid>            
          )}
          </Grid>       
        </FormControl> 
        </Box>
        </div>
    )
}
export default ServiceFields;