import { createMuiTheme } from '@material-ui/core';

const Theme = createMuiTheme({
    // palette: {
    //   primary: {
    //     main: '#2EFF22',
    //   },
    //   secondary: { main: '#22BF19' },
    //   grey: { main: '#22BF19' }
    // },
    overrides: {
      MuiOutlinedInput: {
        root: {
          position: 'relative',
          '& $notchedOutline': {
            borderColor: '#CCC',
          },          
          '&:hover:not($disabled):not($focused):not($error) $notchedOutline': {
            borderColor: '#CCC',
            // Reset on touch devices, it doesn't add specificity
            '@media (hover: none)': {
              borderColor: '#CCC',
            },
          },
          '&$focused $notchedOutline': {
            borderColor: '#CCC',
            borderWidth: 1,
          },          
        },
        
      },
      MuiFormLabel: {
        root: {
          '&$focused': {
            color: '#333'
          },
          
        }
      },
      MuiFormHelperText:{
        root: {
        "&.MuiFormHelperText-root": {
            fontSize:'1rem'
          }
        }
      }
    }
  })
  
  export default Theme