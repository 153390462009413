import React from 'react';
import {  
    Box, 
    Typography,        
    FormControl, 
    makeStyles    
  } from '@material-ui/core';
  import { TextValidator } from 'react-material-ui-form-validator';
  const useStyles = makeStyles((theme) => ({
    formControl: {
      margin: theme.spacing(1, 0, 0, 0),      
      width:'100%'
    },      
    root: {
      maxWidth: 200,
    },
    grid:{
      marginBottom:'10px'
    },
    gridCard:{
      margin:'10px 10px 10px 0'
    },    
  }));
  
const ContactFields = (props) =>{
    const {firstName, setFirstName, lastName, setLastName, email, setEmail, phoneNumber, setPhoneNumber} = props;
    const classes = useStyles();
    
    const handleEmailChange =(event)=> setEmail(event.target.value);
    const handleFirstNameChange =(event)=> setFirstName(event.target.value);
    const handleLastNameChange =(event)=> setLastName(event.target.value);
    const handlePhoneNumberChange =(event)=> setPhoneNumber(event.target.value);
    return(
        <Box my={4}>
          <Typography variant="h5" component="h2" color="textPrimary">
            Contact Information
          </Typography>
          <FormControl component="fieldset" className={classes.formControl}>    
          <TextValidator
              label="Email"
              onChange={handleEmailChange}
              name="email"
              value={email}
              validators={['required', 'isEmail']}
              errorMessages={['Email is required', 'Email is not valid']}
              className={classes.textField}
              margin="normal"
              variant="outlined"              
          />
          <TextValidator
              label="First Name"
              onChange={handleFirstNameChange}
              name="firstName"
              value={firstName}
              validators={['required']}
              errorMessages={['Firstname is required']}
              className={classes.textField}
              margin="normal"
              variant="outlined"              
          />
          <TextValidator
              label="Last Name"
              onChange={handleLastNameChange}
              name="lastName"
              value={lastName}
              validators={['required']}
              errorMessages={['Lastname is required']}
              className={classes.textField}
              margin="normal"
              variant="outlined"              
          /> 
          <TextValidator
              label="Phone Number"
              onChange={handlePhoneNumberChange}
              name="phoneNumber"
              value={phoneNumber}
              validators={['required', 'isNumber']}
              errorMessages={['Phone number is required', 'Phone number is not valid']}
              className={classes.textField}
              margin="normal"
              variant="outlined"              
          />     
        </FormControl> 
        </Box>
        
    )
}
export default ContactFields;