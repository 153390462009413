export default {
    servicesData:[
        {
            slug:'grass-cutting',
            title:'Grass Cutting'
        },
        {            
            slug:'snow-removal',
            title:'Snow Removal'
        }
    ],
    grasscutting:[
        {
            serviceTypeData:[
                {
                    slug:'weekly',
                    title:'Weekly',
                    description:'Recurring grass cut every week'
                },
                {
                    slug:'bi-weekly',
                    title:'Bi-Weekly',
                    description:'Recurring grass cut every other week'
                },
                {
                    slug:'one-time',
                    title:'One Time',
                    description:'One time grass cut'
                }
            ]

        },
        {
            servicePlanData:[
                {
                    slug:'basic',
                    title:'Basic',
                    description:'Grass cut only'
                },
                {
                    slug:'premium',
                    title:'Premium',
                    description:'Grass cut including Garden Beds, Shrubs, and Leaf Cleanup'            
                }
            ]
        }
        
    ],
    snowremoval:[
        {
            serviceTypeData:[
                {
                    slug:'automated',
                    title:'Automated',
                    description:'Snow removal any time there is 2” or 5cm of snow accumulation'
                },                
                {
                    slug:'one-time',
                    title:'One Time',
                    description:'One time snow removal'
                }
            ]

        },
        {
            servicePlanData:[
                {
                    slug:'front-door-walkway',
                    title:'Front Door Walkway',
                    description:''                    
                },
                {
                    slug:'stairs-front-landing',
                    title:'Stairs & Front Landing',
                    description:''            
                }
            ]
        }
        
    ]
        

}